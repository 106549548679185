import ReactPlayer from 'react-player';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PlayerThumbnail from '../assets/img/thumbnail.png'
import GoogleGenAIVideo from '../assets/img/google-gen-ai-demo.mp4'


const MainContainer = () => {
  return <div className="main-container">
    <Container className="h-100 px-0" fluid>
      <Row className="column h-100 g-0">
        <Col md={7} className="h-100" sm>
          <Row className="align-items-center h-100">
            <ReactPlayer
              url={GoogleGenAIVideo}
              controls={true}
              playing={true}
              playIcon={true}
              width='100%'
              height='100%'
              light={PlayerThumbnail}
            />
          </Row>
        </Col>
        <Col>
          <Row className="m-4 fs-2">
            <Col md={10} className="description-title lh-1 m-auto justify-content-center">
              <Row>
                <span className="fw-bold fs-3 text-center" style={{ color: "#cc0000", fontFamily:"Source Sans Pro" }}>Unlock your unstructured data</span>
              </Row>
              <Row><span className="fw-bold fs-3 text-center" style={{ color: "#cc0000", fontFamily:"Source Sans Pro" }}>with Google Generative AI</span></Row>
            </Col>
          </Row>
          <Row className="m-4">
            <p className="summary">
              CDW can help clients make use of their unstructured data by using Google generative AI to automatically generate text,
              images, and code. This can help clients to improve their products and services, create new marketing campaigns, and more.
              For example, a CDW client in the healthcare industry could use generative AI to generate patient reports, which would save
              time and improve accuracy. A CDW client in the financial services industry could use generative AI to create marketing
              materials, such as white papers and case studies, which would help them to reach new customers.
            </p>
            <p className="summary">
              In addition to helping clients with generative AI, CDW can also help them with conversational AI. Conversational AI is a
              type of AI that can interact with humans in a natural way, through text or speech. CDW can help clients build chatbots that
              can answer customer questions, provide product information, and even help with sales.
            </p>
            <p className="summary">
              With CDW's help, businesses can take advantage of the latest AI technologies to improve their customer experience, increase sales,
              and innovate new products and services.
            </p>
            <p className="summary">
              Try out our demo bot below that can answer questions about Google Next sessions as well as CDW products and Services. To
              find out how CDW can help you navigate the AI landscape; contact your CDW account manager or visit us at CDW.com.
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>



};

export default MainContainer;
