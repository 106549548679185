import Header from "./components/Header";
import Hero from "./components/Hero";
import MainContainer from "./components/MainContainer";
import Footer from "./components/Footer";
import Popup from "./components/Modal";
import "./App.css";

const FrameComponent = () => {
  
  return (
    <>
      <Popup />
      <Header />
      <Hero />
      <MainContainer />
      <Footer />
    </>
  );
};

export default FrameComponent;
