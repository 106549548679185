import ICXLogo from "../assets/img/icxlogo.png";
import MenuIcon from '../assets/img/icon.svg';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={ICXLogo} className="icxlogo-1-icon" alt="ICX Logo"/>
      </div>
      <div className="meet-div">
        <div className="meet-eda">
          <span>
            NeXT
            <span className="span">{` `}</span>
          </span>
          <span className="eda">Bot</span>
        </div>
        <div className="menu">
          <img src={MenuIcon} className="icon" alt="Menu Icon"/>
        </div>
      </div>
    </header>
  );
};

export default Header;
