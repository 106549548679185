import HeroBackground from '../assets/img/hero-background.png';

var heroStyle = {
  backgroundImage: `url(${HeroBackground})`
}

const Hero = () => {
  return (
    <div className="hero bg-image" style={heroStyle}>
      <div className="title">
        <div className="embrace-the-future-container">
          <span className="embrace-the-future-container1">
            <p className="embrace-the-future">Embrace the future</p>
            <p className="embrace-the-future">with Google AI</p>
            <p className="cdw-amplified-aiml">CDW Amplified™ AI/ML</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Hero;
