import UnderLineDiv from "../assets/img/divfootertextunderline.svg";
import LinkedInLogo from "../assets/img/LinkedIn.svg";
import TwitterLogo from "../assets/img/Twitter.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content" id="footer-content">
        <div className="we-get-virtual-assistants-parent">
          <b className="we-get-virtual">We get Google AI</b>
          <img
            className="divfooter-text-underline-icon"
            alt=""
            src={UnderLineDiv}
          />
        </div>
        <div className="with-full-stack-expertise-cdw-wrapper">
          <span className="with-full-stack-expertise">
            With full-stack expertise including AI/ML, CDW helps you design, orchestrate and
            manage technologies that drive business success.
          </span>
        </div>
        <div className="ulsocial-icons">
          <div className="linkedin-in-icon" id="linked-in">
            <img className="vector-icon" alt="" src={LinkedInLogo} />
          </div>
          <div className="linkedin-in-icon" id="twitter">
            <img className="vector-icon2" alt="" src={TwitterLogo} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
