import { Modal, Button } from "react-bootstrap";
import React, { Component } from 'react';

class Popup extends Component {
    render() {
        return (
            <Modal>
                <Modal.Header closeButton>
                    <Modal.Title>Demo System</Modal.Title>
                </Modal.Header>
                <Modal.Body>Since this is a demo the backend chat API may be suspsended. If you recieve an error
                    on your first chat attempt; give it a few seconds and try again. 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary">Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Popup;